<template>
  <v-dialog v-if="selectedSession" max-width="1280" v-model="detailsDialog">
    <v-card class="session-tile session-history owner-session">
      <v-card-title class="py-1 pr-2">
        <h3>Device ID: {{ selectedSession.plug_identifier }}</h3>
        <div class="show-by-container">
          <v-btn
            v-if="isValid.voltage || isValid.current"
            color="success"
            :id="`show-by-consumptions-button`"
            @click="selectedField = 'consumptions'"
            :outline="selectedField != 'consumptions'"
            small
            class="mx-0"
          >
            Consumption
          </v-btn>
          <v-btn 
            v-if="isValid.voltage" 
            color="success" 
            :id="`show-by-voltages-button`" 
            @click="selectedField = 'voltages'" 
            :outline="selectedField != 'voltages'" 
            small
            class="mx-0"
          >
            Voltage
          </v-btn>
          <v-btn 
            v-if="isValid.current" 
            color="success" 
            :id="`show-by-currents-button`" 
            @click="selectedField = 'currents'" 
            :outline="selectedField != 'currents'" 
            small 
            class="mx-0"
          >
            Current
          </v-btn>
        </div>
        <v-spacer />
        
        <KillSession
          v-if="selectedSession.active"
          class="mt-1"
          :deviceId="selectedSession.plug_identifier"
          @show="v => detailsDialog = !v"
        /> 

        <DataExporter
          :data="exportableCSV"
          :filename="`Session_History_Data_${$moment().format('YYYYMMDDHHmmss')}.csv`"
          buttonClass="px-3"
          class="mt-1"
          :confirmation="false"
        />
        <div class="mr-4 mt-1">
          <slot></slot>
        </div>
        <v-btn id="session-details-dialog-close-button" icon @click="detailsDialog = false" class="session-log-dialog__close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="selectedSession.data" class="pb-0">
        <div class="pt-2">
          <LogGraph
            chart-id="consumption-chart"
            :raw-data="selectedSession.data"
            :chartLabel="selectedFieldObject.label"
            :dataField="selectedFieldObject.key"
            :yLabel="selectedFieldObject.yLabel"
            color="rgba(3,155,229,1)"
            fill-color="rgba(3,155,229,.1)"
            :loading="detailsLoading"
            :timeformat="selectedSession.graph_timeformat"
          />
        </div>
      </v-card-text>
      <v-layout v-if="!!showEmailAndDescription" row wrap class="session-details">
        <v-flex sm6 xs12 class="session-detail py-3 text-xs-center">
          <span class="text-session-title">{{ userEmail }}</span>
          <small class="text-session-detail">USER</small>
        </v-flex>
        <v-flex sm6 xs6 class="session-detail py-3 text-xs-center xs6">
          <span class="text-session-title">{{ selectedSession && selectedSession.plug ? selectedSession.plug.description : '' }}</span>
          <small class="text-session-detail">DESCRIPTION</small>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="session-details">
        <v-flex xs4 class="session-detail py-3 text-xs-center">
          <span class="text-session-title">{{ selectedSession.duration }}</span>
          <small class="text-session-detail">DURATION</small>
        </v-flex>
        <v-flex xs4 class="session-detail py-3 text-xs-center">
          <span class="text-session-title">{{ selectedSession.consumption }}</span>
          <small class="text-session-detail">CONSUMPTION</small>
        </v-flex>
        <v-flex xs4 class="session-detail py-3 text-xs-center">
          <span class="text-session-title">{{ selectedSession.amount | numeral("0,0.00") }}</span>
          <small v-if="role == 'owner'" class="text-session-detail">
            REVENUE
            <span v-if="selectedSession.wallet" class="text-session-detail" style="font-size: 100%">({{ selectedSession.wallet.currency }})</span>
          </small>
          <small v-else class="text-session-detail">
            TOTAL AMOUNT
            <span v-if="selectedSession.wallet" class="text-session-title">({{ selectedSession.wallet.currency }})</span>
          </small>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="session-details">
        <v-flex xs12 class="session-detail py-4 text-xs-center d-flex flex-row">
          <span class="text-session-title"><small class="mr-2 text-session-detail">PAYMENT PLAN : </small>{{ selectedSession.plug_payment_access_plan ? selectedSession.plug_payment_access_plan.description : "-" }}</span>
        </v-flex>
      </v-layout>

      <v-layout v-if="detailsLoading" xs12 justify-center class="my-4">
        <v-progress-circular indeterminate color="orange" :size="72"></v-progress-circular>
      </v-layout>
      <template v-else>
        <v-layout
          :key="i"
          v-for="(pricing, i) in pricings"
          row
          :wrap="$store.state.responsive"
          class="session-details text-session-title"
        >
          <div class="date-container">
            <v-flex class="session-detail py-4 text-xs-center">
              <span>
                {{ pricing.start || "-" }}
              </span>
              <small  class="text-session-detail">START TIME</small>
            </v-flex>
            <v-flex class="session-detail py-4 text-xs-center">
              <span class="text-session-title">
                {{ pricing.end || "-" }}
              </span>
              <small  class="text-session-detail">END TIME</small>
            </v-flex>
          </div>
          <v-layout row class="detail-container">
            <v-flex v-if="i == 0" class="session-detail py-3 text-xs-center">
              <span class="text-session-title">
                {{ pricing.activation_fee || "-" }}
              </span>
              <small  class="text-session-detail">ACTIVATION FEE ({{ walletCurrency }})</small>
            </v-flex>
            <template v-if="!pricing.disabled">
              <v-flex class="session-detail py-3 text-xs-center">
                <span class="text-session-title">
                  {{ pricing.duration_rate || "-" }}
                </span>
                <small  class="text-session-detail">DURATION RATE ({{ walletCurrency }}/h)</small>
              </v-flex>
              <v-flex class="session-detail py-3 text-xs-center">
                <span class="text-session-title">
                  {{ pricing.consumption_rate || "-" }}
                </span>
                <small  class="text-session-detail">CONSUMPTION RATE ({{ walletCurrency }}/kWh)</small>
              </v-flex>
              <v-flex v-if="isConditionalPlan" class="session-detail py-3 text-xs-center">
                <span class="text-session-title">
                  {{ pricing.amount }}
                </span>
                <small  class="text-session-detail">AMOUNT ({{ walletCurrency }})</small>
              </v-flex>
            </template>
            <v-flex v-else class="session-detail py-3 text-xs-center">
              <span class="text-session-title">Device Disabled ( <span class="text--secondary text-session-detail">No Charge</span> )</span>
            </v-flex>
          </v-layout>
        </v-layout>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { cloneDeep as _cloneDeep, pick as _pick } from 'lodash';
import sessionLog from "@/mixins/sessionLog";
import helpers from "@/mixins/helpers";
import Api from "@/library/apis/Api";
import { findPlan } from "@/library/helpers/paymentPlan"
import { getTimezoneOffset, whToKwh } from '@/library/helpers';
import KillSession from "@/components/modals/KillSession.vue";
import DataExporter from "@/components/modals/DataExporter.vue";
import LogGraph from "@/components/graphs/LogGraph";

export default {
  props: ["selected", "showEmailAndDescription"],
  mixins: [sessionLog, helpers],
  components: { DataExporter, LogGraph, KillSession },
  data() {
    return {
      selectedSession: null,
      detailsDialog: false,
      detailsLoading: false,
      selectedField: "consumptions",
      isValid: {
        consumption: false,
        voltage: false,
        current: false,
      },
      variables: {
        fields: [
          {
            key: "consumptions",
            label: "Consumption",
            yLabel: "kWh",
          },
          {
            key: "voltages",
            label: "Voltage",
            yLabel: "V",
          },
          {
            key: "currents",
            label: "Current",
            yLabel: "A",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      plugs: (state) => state.plugs,
      role: (state) => state.Auth.role,
      timezone: (state) => state.Global.timezone,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    userEmail() {
      return this.role === 'admin' && this.activeAdminView === 'userview' 
        ? this.adminViewAsUser.username
        : this.selectedSession.username || localStorage.getItem("email");
    },
    managerName() {
      if (this.role != "admin" || this.activeAdminView != "managerview" || !this.viewAsOwner) return localStorage.getItem("email");
      return this.viewAsOwner.username;
    },
    walletCurrency() {
      if (this.selectedSession.wallet && this.selectedSession.wallet.currency) return this.selectedSession.wallet.currency;
      return null;
    },
    selectedFieldObject() {
      return this.variables.fields.find((f) => f.key == this.selectedField);
    },
    isConditionalPlan() {
      return !!this.selected && !!this.selected.plug_payment_access_plan && !!this.selected.plug_payment_access_plan.timezone
    },
    pricings() {
      if (this.detailsLoading || !this.selectedSession || !this.selectedSession.pricings) return []
      return this.selectedSession.pricings.map((pricing, i) => {
        const disabled = pricing.plan ? pricing.plan.disabled : false
        let duration_rate = pricing.plan ? this.formatRate(pricing.plan.hRate, "") : null
        let consumption_rate = pricing.plan ? this.formatRate((pricing.plan.whRate * 1000000000) / 1000000, "") : null
        let amount = pricing.plan ? this.formatRate(pricing.plan.amount || 0, "") : "0"

        if (disabled)
          duration_rate = consumption_rate = amount = `Device Disabled (No Charge)`

        return {
          start: pricing.time && pricing.time.start ? pricing.time.start.format("YYYY-MM-DD hh:mm:ss A") : null,
          start_timestamp: pricing.time?.start ? pricing.time.start.unix() : null,
          end: pricing.time && pricing.time.end ? pricing.time.end.format("YYYY-MM-DD hh:mm:ss A") : null,
          end_timestamp: pricing.time?.end ? pricing.time.end.unix() : null,
          activation_fee: i === 0 && pricing.plan ? this.formatCost(pricing.plan.startSessionCost) : null,
          duration_rate,
          consumption_rate,
          amount,
          disabled
        }
      })
    },
    logs() {
      if (this.detailsLoading || !this.selectedSession || !this.selectedSession.data) return []
      return this.selectedSession.data.map(log => ({
        consumption: log.consumptionkWh || 0,
        current: log.current || 0,
        time: this.$moment(log.logitem_timestamp * 1000).format("YYYY-MM-DD HH:mm:ss"),
        voltage: log.voltage || 0
      }))
    },
    exportableCSV() {
      const currency = this.walletCurrency || ""

      return (
        (this.role !== "user" && this.activeAdminView != "userview" ? `Manager:,${this.managerName},,,,\n` : "") +
        `Time Zone (GMT):,${getTimezoneOffset()},,,,\n` +
        `User:,${this.userEmail},,,,\n` +
        `Device ID:,${this.selectedSession && this.selectedSession.plug ? this.selectedSession.plug_identifier : ""},,,,\n` +
        `Description:,${this.selectedSession && this.selectedSession.plug ? this.selectedSession.plug.description : ""},,,,\n` +
        `Total Duration (HH:MM:SS):,${this.selectedSession ? (this.selectedSession.duration || "00:00:00") : "00:00:00"},,,,\n` +
        `Total Consumption (kWh):,${this.selectedSession ? whToKwh(this.selectedSession.total_consumption || 0, 3) : 0},,,,\n` +
        `Total Revenue (${currency}):,${this.selectedSession ? (this.selectedSession.amount || "0.00") : "0.00"},,,,\n` +
        `Payment Plan:,${this.selectedSession && this.selectedSession.plug_payment_access_plan ? this.selectedSession.plug_payment_access_plan.description : ""},,,,\n` +
        `Payment Plan Details:,${this.selectedSession && this.selectedSession.plug_payment_access_plan ? `"${JSON.stringify(this.selectedSession.plug_payment_access_plan).replaceAll('"', '""')}"` : ""},,,,\n\n` +

        `Payment Breakdown,,,,,\n` +
        `Start Time (YYYY-MM-DD HH:MM:SS),End Time (YYYY-MM-DD HH:MM:SS),Activation Fee (${currency}),Duration Rate (${currency}/H),Consumption Rate (${currency}/KWH),Amount (${currency})\n` +
        `${this.pricings.reduce((text, pricing) => `${text}${this.$moment.unix(pricing.start_timestamp).format("YYYY-MM-DD HH:mm:ss")},${this.$moment.unix(pricing.end_timestamp).format("YYYY-MM-DD HH:mm:ss")},${pricing.activation_fee},${pricing.duration_rate},${pricing.consumption_rate},${pricing.amount}\n`, "")} \n\n` +
        
        `Session Logs,,,,,\n` +
        `Time (YYYY-MM-DD HH:MM:SS),Consumption (KWH),Voltage (V),Current (V)\n` +
        `${this.logs.reduce((text, log) => `${text}${log.time},"${log.consumption}","${log.voltage}","${log.current}",,\n`, "")}\n`
      );
    },
  },
  methods: {
    openDetails(session) {
      this.detailsLoading = true;

      this.selectedSession = session;      

      this.selectedSession.data = [];
      this.detailsDialog = true;
      const start = session.starttime ? this.$moment(session.starttime * 1000).tz(this.timezone) : null;
      const end = session.endtime ? this.$moment(session.endtime * 1000).tz(this.timezone) : null;
      const default_pricing = {
        plan: {
          hRate: session.plug_payment_access_plan.hRate,
          whRate: session.plug_payment_access_plan.whRate,
          startSessionCost: session.plug_payment_access_plan.startSessionCost,
          amount: this.getAmount(_cloneDeep(session.plug_payment_access_plan), session.durationRaw, session.total_consumption, true)
        },
        time: { start: start, end: end },
      };

      const pricings = this.isConditionalPlan ? [] : [default_pricing];

      Api.sessionLogs(this.role, { sessionId: session.id, since: session.starttime, till: session.updated + 1 })
        .then((sessionLogData) => {
          this.processLog(session, sessionLogData, this.timezone, (data) => {
            this.selectedSession.data = data;
            this.isValid = {
              consumption: data.some((e) => e.consumption >= 0),
              voltage: data.some((e) => e.voltage >= 0),
              current: data.some((e) => e.current >= 0),
            };
            if (this.isConditionalPlan) {
              for (let i = 1; i < data.length; i++) {
                const d = data[i];
                const timestamp = this.$moment(d.logitem_timestamp * 1000);
                const session_start = this.$moment(data[i - 1].logitem_timestamp * 1000);
                const duration = d.logitem_timestamp - session.starttime;
                const consumption = d.consumption;
                const session_end = timestamp;
                const pricing = _cloneDeep(findPlan({ plan: session.plug_payment_access_plan, momenttime: timestamp, duration, consumption }));
                const amount = this.getAmount(_cloneDeep(pricing), (session_end - session_start) / 1000, data[i].consumption - data[i-1].consumption, pricings.length === 0);

                const lastPricing = pricings[pricings.length - 1] ? pricings[pricings.length - 1].plan : {};
                const isPricingSame = lastPricing.hRate == pricing.hRate && lastPricing.whRate == pricing.whRate;
                if (pricings.length > 0 && isPricingSame) {
                  pricings[pricings.length - 1].time.end = session_end.tz(this.timezone);
                  pricings[pricings.length - 1].plan.amount += amount;
                } else if (pricings.length === 0 || !isPricingSame) {
                  pricing.amount = amount;
                  pricings.push({ plan: pricing, time: { start: session_start.tz(this.timezone), end: session_end.tz(this.timezone) } });
                }
              }
            }
            this.selectedSession.pricings = pricings;
          });
        })
        .catch((error) => console.error(error))
        .finally(() => (this.detailsLoading = false));
    },
    getAmount(pricing, duration, consumption, includeStart = false) {
      let amount = 0;
      if (includeStart && pricing.startSessionCost) amount += pricing.startSessionCost;
      if (pricing.hRate) amount += duration / 3600 * pricing.hRate;
      if (pricing.whRate) amount += consumption * pricing.whRate;
      return amount;
    }
  },
  watch: {
    selected() {
      if (this.selected) this.openDetails(this.selected);
    },
    detailsDialog(v) {
      if (v) this.selectedField = "consumptions";
    },
  },
};
</script>
<style lang="scss">
.text-session {
  &-title {
    font-weight: 500;
    color: #333333;
  }
  &-detail {
    font-size: small;
    color: lightslategray;
    font-family: unset;
  }
}
.session-details {
  cursor: auto;
  user-select: text;
  .date-container {
    width: 30%;
    display: flex;
    flex-direction: row;
    border-right: 1px solid #f2f2f2;
    @media only screen and (max-width: 460px) {
      width: 100%;
    }
  }
  .detail-container {
    flex: 1 1 auto;
  }
  .session-detail {
    align-items: center;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
}
.show-by-container {
  margin-left: 12px;
  display: inline-flex;
  gap: 8px;
  @media only screen and (max-width: 460px) {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.session-log-dialog {
  &__close {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 0px;
    &:focus, &:hover {
      position: absolute;
    }
  }
}
</style>
