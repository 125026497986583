<template>
  <v-dialog v-model="show" max-width="450">
    <v-card>
      <v-card-title>
        <h3>Reactivate Device</h3>
      </v-card-title>
      <v-card-text>
        <v-flex v-if="loading" class="flex justify-center align-center text-xs-center">
          <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="orange" />
        </v-flex>
        <v-flex xs12 v-else>
          <div class="justify-center align-center text-xs-left">
            <span
              class="text-xs-left my-2"
            >
              Please reach out to our customer support team 
              if you would like to reactivate your devices. <br>
              <br>
              Device reactivations are subjected to reactivation fee.
            </span>
          </div>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat="flat" @click="showIntercom">
          CHAT WITH US
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
  name: "ReactivateDialog",
  props: {
    show: { 
      type: Boolean,
      default: false 
    },
    loading: { 
      type: Boolean,
      default: false
    }
  },
  methods:{ 
    showIntercom() {
      this.$emit('close'); 
      this.$store.dispatch('Global/toggleIntercom');
    }
  }
};
</script> 