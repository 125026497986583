<template>
  <v-dialog v-model="showDialog" max-width="400">
    <v-card>
      <v-card-title>
        <h3>Device Cloud Subscription</h3>
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <v-flex xs12>
          <p>
            Some of your Plugzio device(s) cloud access are schedules to expire on <strong>{{ expirationDate }}</strong
            >. Expired devices will no longer be accessable by the Plugzio cloud for both you and your users. If you would like to continue your device's subscription, you can do
            at the "Service Subscription" page.
          </p>
          <v-btn class="plugzio-button mt-4 wifi-setup-button" block outline round @click="goToBillingPage()">
            GO TO SERVICE SUBSCRIPTION PAGE
          </v-btn>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" flat="flat" @click="showDialog = false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    timestamp: {
      required: true,
    },
  },
  data() {
    return {
      showDialog: this.value,
    };
  },
  computed: {
    expirationDate() {
      let date = this.$moment.unix(this.timestamp);
      return date.format("DD-MMM-YYYY");
    },
  },
  watch: {
    showDialog: function(value) {
      this.$emit("input", value);
    },
    value: function(value) {
      this.showDialog = value;
    },
  },
  methods: {
    goToBillingPage() {
      this.showDialog = false;
      this.$router.push({ path: "/owner/service-subscription" });
    },
  },
};
</script>
