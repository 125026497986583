<template>
  <v-dialog v-model="visible" max-width="400">
    <v-card>
      <v-card-title>
        <h3>Low Wallet Balance Warning</h3>
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <v-flex class="flex flex-column items-center">
          <p>Wallet Amount (<b>{{ wallet.currency }}</b>): <b>{{ floorNumberToTwoDecimal(wallet.balance) | numeral("0,0.00")}}</b></p>
          <p>Your charging session will get interrupted when you have insufficient funds.</p>

          <TopUpModal
            :wallet="wallet"
            :cancelCallback="() => visible = true"
            @success="$emit('topup-done')"
            class="mt-2"
          >
            <v-btn
              class="plugzio-button mb-2 mt-4 wifi-setup-button"
              block
              outline
              round
              @click="visible = false"
            >
              <v-icon size="18" style="margin: 0" key="icon">account_balance_wallet</v-icon>
              <span class="ml-1" key="description">Top Up Now</span>
            </v-btn>
          </TopUpModal>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="gray"
          flat="flat"
          @click="visible = false"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          flat="flat"
          :loading="!!$store.state.loading"
          @click="$emit('confirmed')"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { floorNumberToTwoDecimal } from '@/library/helpers';
import TopUpModal from './TopUp/TopUpModal.vue';

export default {
  name: 'LowWalletBalanceWarning',
  components: {
    TopUpModal
  },
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    wallet: {
      type: Object,
      required: true,
    }
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    floorNumberToTwoDecimal
  }
}
</script>