<template>
  <v-dialog v-model="showModal" max-width="600">
    <v-card>
      <v-card-title>
        <h3>Refund</h3>
      </v-card-title>
      <v-card-text>
        <v-alert class="mb-2 py-2" :value="error" type="error" dismissible>
          {{ error }}
        </v-alert>
        <v-layout>
          <v-flex>
            <v-form ref="refundForm" v-model="valid">
              <v-text-field
                v-model="inputs.amount"
                label="Amount"
                outline
                autocomplete="false"
                :rules="[rules.required, rules.withValue, rules.twoDecimalNumber, rules.number]"
                class="mb-2"
                autofocus
                min="0"
                type="number"
              />
              <v-text-field
                v-model="inputs.memo"
                label="Memo"
                outline
                :rules="[rules.required]"
                class="mb-0"
              />
            </v-form>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="showModal = false" :loading="loading">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="refundInputValid"
          @click="confirm()"
          color="green darken-1"
          flat="flat"
          :loading="loading"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/library/apis/Api";
import mixin from "@/mixins";
import { mapState } from "vuex"

export default {
  mixins: [mixin],
  props: {
    wallet: Object,
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: this.value,
      inputs: {
        amount: null,
        memo: null,
      },
      valid: true,
      loading: false,
      error: null
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    refundInputValid() {
      return (
        !!this.inputs.amount &&
        !!this.inputs.memo &&
        this.inputs.amount > 0 
      )
    },
  },
  watch: {
    value: function(value) {
      this.showModal = value;
    },
    showModal: function(value) {
      if (!value) this.resetInput();
      this.$emit("input", value);
    },
  },
  methods: {
    resetInput() {
      this.inputs = {
        amount: null,
        memo: null,
      }
    },
    async confirm() {
      this.error = null;
      this.loading = true;

      let payload = {
        walletId: this.wallet.id,
        amount: parseFloat(this.inputs.amount),
        memo: this.inputs.memo,
      };
  
      await Api.walletsRefund(this.role, payload)
        .then((response) => {
          this.$store.dispatch("snackbar", { message: "Money was deducted from the wallet" });
          this.showModal = false; 
          this.inputs.amount = null;
          this.inputs.memo = null;

          if (this.$refs.refundForm) {
            this.$refs.refundForm.reset();
          }
        })
        .catch((error) => {
          this.$store.dispatch("snackbar", { message: "Refund was unsuccessful" });
          console.error(error);
          this.error = err.response.data    
        })
        .finally(() => {
          this.loading = false;
        });

      this.$nextTick(() => {
        this.$emit("success");
      })
    }
  },
};
</script>
