<template>
  <v-flex v-if="!!loading" xs12 class="align-center d-flex justify-center">
    <v-progress-circular class="loading-circle d-flex mb-3" size="25" indeterminate color="orange" />
  </v-flex>

  <v-flex v-else v-show="menu === 'summary-details'" xs12 class="align-center justify-center">
    <template v-if="options.selectedPaymentMethod !== 'wire' && options.selectedPaymentMethod !== 'ach'">
      <h4 class="profile-summary-title" :class="isProfileSelection ? 'mt-0' : 'mt-5'">
        <strong>{{ isProfileSelection ? "Summary" : "Profile Summary" }}</strong>
      </h4>
      <div class="profile-summary-content">
        <p class="mb-0">
          Wallet: <strong>{{ wallet.description }}</strong>
        </p>
        <p class="mb-0">
          Currency: <strong>{{ wallet.currency }}</strong>
        </p>
        <p v-if="isProfileSelection" class="mb-0">
          Current Balance: <strong>{{ wallet.balance }}</strong>
        </p>
        <p v-if="isProfileSelection" class="mb-0">
          Withdrawal Amount: <strong>{{ inputsAmount }}</strong>
        </p>
      </div>
      <div class="mt-4 mb-4" :class="isProfileSelection ? '' : 'box'">
        <template v-if="options.selectedPaymentMethod == 'paypal'">
          <h4 class="mt-0 ml-2">
            <strong>{{ isProfileSelection ? "" : "Paypal Information" }}</strong>
          </h4>
          <p v-if="isProfileSelection" class="mb-0">
            Withdrawal Method:
            <strong>{{
              options.selectedPaymentMethod == "ach"
                ? options.selectedPaymentMethod.toUpperCase()
                : options.selectedPaymentMethod.charAt(0).toUpperCase() + options.selectedPaymentMethod.slice(1).toLowerCase()
            }}</strong>
          </p>
          <p v-if="!isProfileSelection" class="mb-0 ml-4">
            Profile Name: <strong>{{ dataSummary.profileName }}</strong>
          </p>
          <p class="mb-0" :class="isProfileSelection ? 'ml-0' : 'ml-4'">
            E-mail: <strong>{{ isProfileSelection ? profiles.paypalEmail : dataSummary.paypalEmail }}</strong>
          </p>
        </template>
        <template v-else-if="options.selectedPaymentMethod == 'cheque'">
          <h4 class="mt-0 ml-2"><strong>Cheque Information</strong></h4>
          <p class="mb-0 ml-4">
            Pay To The Order Of: <strong>{{ isProfileSelection ? profiles.chequeInfo.payToTheOrderOf : dataSummary.chequeInfo.payToTheOrderOf }}</strong>
          </p>
          <p v-if="isProfileSelection" class="mb-0 ml-4">
            Memo: <strong>{{ inputsMemo }}</strong>
          </p>
          <div v-if="isProfileSelection">
            <p class="mb-0 ml-4">
              Amount: <strong>{{ inputsAmount }}</strong>
            </p>
            <p class="mb-0 ml-4">
              Currency: <strong>{{ wallet.currency }}</strong>
            </p>
          </div>
          <h4 class="ml-2 mt-2"><strong>Mailing Information</strong></h4>
          <p class="mb-0 ml-4">
            Name of Receiver: <strong>{{ isProfileSelection ? profiles.mailingInfo.nameOfReceiver : dataSummary.mailingInfo.nameOfReceiver }}</strong>
          </p>
          <p class="mb-0 ml-4">
            Phone Number: <strong>{{ isProfileSelection ? profiles.mailingInfo.phoneNumber : dataSummary.mailingInfo.phoneNumber }}</strong>
          </p>
          <p class="mb-0 ml-4">
            E-mail Address: <strong>{{ isProfileSelection ? profiles.mailingInfo.email : dataSummary.mailingInfo.email }}</strong>
          </p>
          <p class="mb-0 ml-4">
            Mailing Address: <strong>{{ isProfileSelection ? profiles.mailingInfo.address : dataSummary.mailingInfo.address }}</strong>
          </p>
        </template>
      </div>
    </template>

    <template v-if="options.selectedPaymentMethod == 'wire' || options.selectedPaymentMethod == 'ach'">
      <h4 class="profile-summary-title" :class="isProfileSelection ? 'mt-0' : 'mt-5'">
        <strong>{{ isProfileSelection ? "Summary" : "Profile Summary" }}</strong>
      </h4>
      <div v-if="!isProfileSelection" class="profile-summary-content">
        <p class="mb-0">
          Wallet: <strong>{{ wallet.description }}</strong>
        </p>
        <p class="mb-0">
          Currency: <strong>{{ wallet.currency }}</strong>
        </p>
      </div>
      <div class="mt-4 mb-4" :class="isProfileSelection ? '' : 'box'">
        <h4 class="mt-0 ml-2">Customer Information</h4>
        <p class="ml-4 mb-0">
          Name: <strong>{{ isProfileSelection ? profiles.customerInfo.name : dataSummary.customerInfo.name }}</strong>
        </p>
        <p class="ml-4 mb-0">
          E-mail: <strong>{{ isProfileSelection ? profiles.customerInfo.email : dataSummary.customerInfo.email }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Phone Number:
          <strong>{{ isProfileSelection ? profiles.customerInfo.phoneNumber : dataSummary.customerInfo.phoneNumber }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Address: <strong>{{ isProfileSelection ? profiles.customerInfo.address : dataSummary.customerInfo.address }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Type: <strong>{{ isProfileSelection ? profiles.customerInfo.type : dataSummary.customerInfo.type }}</strong>
        </p>
        <br />
        <h4 class="mt-0 ml-2">Bank Information</h4>
        <p class="ml-4 mb-0">
          Name: <strong>{{ isProfileSelection ? profiles.bankInfo.name : dataSummary.bankInfo.name }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Address: <strong>{{ isProfileSelection ? profiles.bankInfo.address : dataSummary.bankInfo.address }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Account Number: <strong>{{ isProfileSelection ? profiles.bankInfo.account : dataSummary.bankInfo.account }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Swift Code: <strong>{{ isProfileSelection ? profiles.bankInfo.code : dataSummary.bankInfo.code }}</strong>
        </p>
        <p v-if="options.selectedPaymentMethod === 'ach'" class="ml-4 mb-0">
          ABA Number: <strong>{{ isProfileSelection ? profiles.bankInfo.abaNumber : dataSummary.bankInfo.abaNumber }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Routing Code: <strong>{{ isProfileSelection ? profiles.bankInfo.routing : dataSummary.bankInfo.routing }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Transit Number:
          <strong>{{ isProfileSelection ? profiles.bankInfo.transit || "N/A" : dataSummary.bankInfo.transit || "N/A" }}</strong>
        </p>
        <p class="ml-4 mb-0">
          Institution Number:
          <strong>{{ isProfileSelection ? profiles.bankInfo.institution || "N/A" : dataSummary.bankInfo.institution || "N/A" }}</strong>
        </p>
      </div>
      <div v-if="isProfileSelection">
        <h4 class="mt-0 ml-2">Transaction</h4>
        <p v-if="isProfileSelection" class="mb-0 ml-4">
          Amount: <strong>{{ inputsAmount }}</strong>
        </p>
        <p v-if="isProfileSelection" class="mb-0 ml-4">
          Currency: <strong>{{ wallet.currency }}</strong>
        </p>
        <br />
        <hr style="height:2px;border-width:0;color:black;background-color:black" />
      </div>
    </template>
    <p v-if="isProfileSelection && options.selectedPaymentMethod !== 'paypal'" class="mt-3 text-xs-justify">
      Note: withdrawal by {{ options.selectedPaymentMethod == "cheque" ? "cheque" : options.selectedPaymentMethod == "wire" ? "wire" : "ACH" }} are subjected to mailing and
      processing fees. Customer support will contact you by email to confirm the withdrawal
    </p>
    <p v-if="isProfileSelection && options.selectedPaymentMethod == 'paypal'" class="mt-3 text-xs-justify">
      Customer support will contact you by e-mail to confirm the withdrawal.
    </p>
  </v-flex>
</template>

<script>
export default {
  name: "ProfileWithdrawalSummary",
  props: {
    menu: String,
    loading: Boolean,
    isProfileSelection: {
      type: Boolean,
      default: false,
    },
    inputsAmount: {
      type: String,
      default: "",
    },
    inputsMemo: {
      type: String,
      default: "",
    },
    profiles: {
      type: Object,
      default: () => ({}),
    },
    inputs: {
      type: Object,
      default: () => ({
        wireList: {
          customerInfo: {},
          bankInfo: {},
        },
        achList: {
          customerInfo: {},
          bankInfo: {},
        },
        chequeList: {
          chequeInfo: {},
          mailingInfo: {},
        },
      }),
    },
    wallet: {
      type: Object,
      required: true,
    },
    selectedPayment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dataSummary: {
      get() {
        return this.inputs;
      },
      set() {
        return this.$emit("inputs:summary", v);
      },
    },
    options: {
      get() {
        return this.selectedPayment;
      },
      set() {
        return this.$emit("selected-payment", v);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.profile-summary-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.profile-summary-content {
  margin-top: 16px;
}

.box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  background-color: #f9f9f9;
  margin-top: 12px;
}
</style>
