<template>
  <div class="top-up-modal">
    <div class="top-up-modal__toggler d-flex flex w-full" @click="() => !disabled ? (isVisible = true) : {}">
      <slot v-if="$slots.default" />
      <v-hover v-else>
        <button @click="isVisible = true" class="button-onlyicon ml-2 v-btn--small pa-1" slot-scope="{ hover }">
          <v-scale-transition group>
            <v-icon size="18" style="margin: 0" key="icon">launch</v-icon>
            <span v-if="hover" class="ml-1" key="description">Open</span>
          </v-scale-transition>
        </button>
      </v-hover>
    </div>
    <v-dialog v-model="isVisible" max-width="385">
      <v-card>
        <v-card-title>
          <h3>Top Up</h3>
        </v-card-title>
        <v-alert :value="error" type="error">{{ error }}</v-alert>
        <v-card-text class="text-xs-center" v-if="inputs.wallet">
          <div v-show="!busy">
            <h3
              v-if="!mobikwik.active"
              class="mb-3"
            >
              {{ inputs.wallet.description }}
            </h3>
            <div
              v-if="isHandlePaymentDialog"
              :class="[`payment-with-${paymentMethod}`]"
            >
              <div
                v-if="!mobikwik.active"
                class="text-xs-left mb-4"
              >
                Currency:
                <strong>{{ inputs.wallet.currency }}</strong>
                <br />Deposit:
                <strong>{{ walletSign }} {{ inputs.amount | numeral("0,0.00") }}</strong>
                <!-- Hide Display Fee/Rates PayPal-->
                <!-- <br />Paypal Fees:
                <strong>{{ walletSign }} {{ inputs.feesDisplay }}</strong>
                <br />Deposited Amount:
                <strong>{{ walletSign }} {{ inputs.deposited }}</strong> -->
              </div>

              <template v-if="paymentMethod === 'india'">
                <Razorpay
                  v-if="!mobikwik.active && !isIos"
                  class="mb-2"
                  :wallet="inputs.wallet"
                  :amount="inputs.amount"
                  @error="error = $event"
                  @busy="busy = $event"
                  @done="handleDone"
                />
                <Mobikwik
                  :wallet="inputs.wallet"
                  :amount="inputs.amount"
                  @success="handleDone"
                  @failed="cancelTopUp"
                />
              </template>
              <Paypal
                v-else
                :wallet="inputs.wallet"
                :amount="inputs.amount"
                @error="error = $event"
                @busy="busy = $event"
                @done="handleDone"
              />
            </div>
            <v-form v-show="!isHandlePaymentDialog" ref="topUpForm" @submit="checkTopUp()">
              <v-text-field
                id="topup-input-amount"
                v-model="inputs.amount"
                label="Amount"
                outline
                autocomplete="false"
                required
                class="mb-2"
                type="number"
                :rules="[rules.withValue]"
                @keypress="onlyForCurrency"
                autofocus
              />
              <v-checkbox id="topup-processing-fee-checkbox" class="ma-0" v-model="inputs.feeAgree" required color="success" hide-details :rules="[(v) => !!v || '']">
                <template v-slot:label>
                  <span v-if="paymentMethod === 'paypal'" style="font-size: .85em">
                    Deposits are subjected to PayPal transactions & processing fees. 
                    Click <a href="#" @click.stop="openPaypalPage" style="color: #3498db;"><strong>HERE</strong></a> for more information.
                  </span>
                  <span v-else style="font-size: .85em"> 
                    Deposits maybe subjected to transaction & processing fees.
                  </span>
                </template>
              </v-checkbox>
            </v-form>
          </div>
          <div v-if="busy" class="text-xs-center pt-4">
            <v-progress-circular class="loading-circle mb-4" size="50" indeterminate color="orange" />
          </div>
        </v-card-text>
        <v-card-actions v-if="!busy && !mobikwik.active">
          <template v-if="error">
            <v-spacer></v-spacer>
            <v-btn color="gray" flat="flat" @click="cancelTopUp()">OK</v-btn>
          </template>
          <template v-else>
            <v-btn v-if="isHandlePaymentDialog" id="topup-dialog-back-button" color="gray" flat="flat" @click="backTopUp()">Back</v-btn>
            <v-btn v-else color="gray" id="topup-dialog-cancel-button" flat="flat" @click="cancelTopUp()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!isHandlePaymentDialog" id="topup-dialog-next-button" color="green darken-1" flat="flat" @click.stop.prevent="checkTopUp()">NEXT</v-btn>
            <v-btn v-else color="gray" id="topup-dialog-cancel-button" flat="flat" @click="cancelTopUp()">Cancel</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "@/mixins";
import Paypal from "./Paypal.vue"
import Razorpay from "./Razorpay.vue"
import Mobikwik from "./Mobikwik.vue"
import { mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
export default {
  name: "TopUpModal",
  props: {
    wallet: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cancelCallback: {
      type: Function,
      default: () => {},
    }
  },
  components: {
    Paypal,
    Razorpay,
    Mobikwik
  },
  mixins: [mixin],
  data() {
    return {
      isVisible: false,
      inputs: {
        amount: "",
        wallet: null,
        feeAgree: false,
        // feePercentage: 2.9,
        // feePerTransaction: 0.3,
        // fees: 0,
        // feesDisplay: 0,
        // deposited: 0,
      },
      error: null,
      busy: false,
      isHandlePaymentDialog: false,
    };
  },
  computed: {
    ...mapState({
      mobikwik: state => state.Wallet.topup.mobikwik,
    }),
    walletSign() {
      if (this.inputs.wallet) {
        if (this.inputs.wallet.currency === "USD" || this.inputs.wallet.currency === "CAD") return "$";
      }
      return "";
    },
    paymentMethod() {
      if (this.inputs.wallet) {
        return this.inputs.wallet.currency === "INR" ? "india" : "paypal";
      }
      return "paypal"
    },
    isIos() {
      return Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios"
    }
  },
  watch: {
    isVisible(v) {
      this.$store.commit("Global/setReloadOnStateChange", !v)
      this.busy = false
      this.error = null;
      this.isHandlePaymentDialog = false;
      this.inputs.amount = ""
      this.inputs.wallet = this.wallet;
      this.inputs.feeAgree = false
      this.$nextTick(() => {
        try {
          if (this.$refs.topUpForm) this.$refs.topUpForm.reset();
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
  methods: {
    openPaypalPage() {
      window.open('http://www.paypal.com/ca/webapps/mpp/merchant-fees');
    },
    checkTopUp() {
      if (this.$refs.topUpForm.validate()) {
        this.isHandlePaymentDialog = true;
        // this.inputs.fees = this.inputs.amount * (this.inputs.feePercentage / 100) + this.inputs.feePerTransaction;
        // this.inputs.feesDisplay = this.roundNumber(this.inputs.fees.toFixed(3));
        // this.inputs.deposited = (this.inputs.amount - Number(this.inputs.feesDisplay)).toFixed(2);
      }
    },
    cancelTopUp() {
      this.isHandlePaymentDialog = false;
      this.$refs.topUpForm.reset();
      this.isVisible = false;
      this.cancelCallback()
      // this.inputs.fees = 0;
      // this.inputs.feesDisplay = 0;
      // this.inputs.deposited = 0;
    },
    backTopUp() {
      this.isHandlePaymentDialog = false;
      // this.inputs.fees = 0;
      // this.inputs.feesDisplay = 0;
      // this.inputs.deposited = 0;
    },
    onlyForCurrency($event) {
      if (this.inputs.amount && this.inputs.amount.indexOf(".") > -1 && this.inputs.amount.split(".")[1].length > 1) {
        $event.preventDefault();
      }
    },
    roundNumber(str_number, precision = 2, round_up_minimum = 5) {
      const last_number = str_number.charAt(str_number.length - 1);
      str_number = str_number.substring(0, str_number.length - 1);
      // let rounded_number = str_number.substring(0, str_number.length - 1);
      if (last_number >= round_up_minimum) {
        // const rounded_last_number =
        //     parseInt(str_number.charAt(str_number.length - 1)) + 1;
        // if (rounded_last_number > 9) {

        // }
        // rounded_number += rounded_last_number;
        return Number(str_number + (parseInt(last_number) + 1)).toFixed(precision);
      }
      // return rounded_number;
      return str_number;
    },
    handleDone() {
      this.$nextTick(() => {
        this.$emit("success");
        this.isVisible = false;
      })
    }
  },
};
</script>
