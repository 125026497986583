<template>
  <div
    id="razorpay-button"
    :class="{
      busy: buttonBusy
    }"
  >
    <img
      v-if="!buttonBusy"
      src="../../../assets/razorpay.svg" alt="Razorpay Logo" height="24px"
    >
    <v-progress-circular
      v-else
      class="loading-circle center-margin"
      size="24"
      indeterminate
      color="white"
    />
  </div>
</template>

<script>
import Api from '@/library/apis/Api';
import { Checkout } from "capacitor-razorpay"
import { Capacitor } from "@capacitor/core"
export default {
  name: "Razorpay",
  props: {
    wallet: {
      type: Object,
      default: () => ({}),
      required: true
    },
    amount: {
      type: String,
      default: "0",
      required: true
    },
  },
  data() {
    return {
      button: null,
      error: null,
      busy: false,
      buttonBusy: false
    }
  },
  watch: {
    error(v) {
      this.$emit("error", v);
    },
    busy(v) {
      this.$emit("busy", v);
    },
  },
  methods: {
    getError(error) {
      let errorMessage = error
      try {
        let errorResponse = JSON.parse(error.code)
        errorMessage = errorResponse.description
      } catch (e) {
        console.error("Error when parsing error response")
        console.error(e)
      }
      return errorMessage
    },
    loadScript(callback) {
      const existingScript = document.getElementById("razorpay-script");
      if (existingScript) existingScript.parentNode.removeChild(existingScript);

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.id = "razorpay-script";
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };

      if (existingScript && callback) callback();
    },
    async load() {
      this.buttonBusy = true
      const amount = this.amount * 100 // in paisa (1 INR = 100 paisa)
      
      const { id: order_id } = await Api.walletRazorpayCreateOrder({ amount });

      let self = this;
      const options = {
        key: window.RAZORPAY_KEY || process.env.VUE_APP_RAZORPAY_KEY,
        amount,
        currency: "INR",
        name: "Plugzio Power",
        description: "Top Up INR wallet",
        image: "https://static.wixstatic.com/media/689997_b6dac99e2a884c88ad26cdce6624e65e~mv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/689997_b6dac99e2a884c88ad26cdce6624e65e~mv2.png",
        order_id,
        //    callback_url: "https://api-staging.plugz.io/wallet/topup/rayzorpay",  
        prefill: {
          name: localStorage.getItem("email"),
          email: localStorage.getItem("email"),
          contact: "9000090000" // There's no contact saved right now, using dummy contact
        },
        notes: {
          address: "Razorpay Corporate Office",
          walletId: this.wallet.id
        },
        theme: {
          color: "#3399cc"
        },
        handler: async () => {
          self.busy = true;
          try {
            await Api.walletTopupRazorpay(JSON.stringify({ orderId: order_id }))
            self.$emit("done");
          } catch (error) {
            console.error("Error when handling topup")
            console.log(error);
          }
          self.busy = false;
        },
        modal: {
          "ondismiss": function () {
            self.busy = false;
            console.log("User cancel the payment");
          }
        }
      }

      try {
        this.button = null;
      } catch (e) {
        console.log(e);
      }

      if (!Capacitor.isNativePlatform() || Capacitor.getPlatform() === "ios") {
        this.loadScript(() => {
          this.buttonBusy = false;
          this.$nextTick(() => {
            const razorpay = new Razorpay(options)
            razorpay.on("payment.failed", ({ error: errorResponse }) => {
              self.error = errorResponse.description
            })
            document.getElementById("razorpay-button").onclick = (e) => {
              e.preventDefault();
              self.error = null;
              self.busy = true;
              razorpay.open();
            }
          });
        });
      } else {
        try {
          this.buttonBusy = false;
          document.getElementById("razorpay-button").onclick = async (e) => {
            try {
              e.preventDefault();
              self.error = null;
              self.busy = true;
              await Checkout.open(options).catch()
              self.busy = true;
              try {
                await Api.walletTopupRazorpay(JSON.stringify({ orderId: order_id }))
                self.$emit("done");
              } catch (error) {
                console.error("Error when trying to topup wallet", error)
                self.error = error.message || error.data || error
              }
              self.busy = false;
            } catch (error) {
              self.busy = false;
              console.error("Error when trying to open checkout")
              const errorMessage = self.getError(error)
              if (errorMessage.includes("You may have cancelled the payment")) {
                console.log("User cancelled the payment")
              }
            }
          }
        } catch (error) {
          console.error("Error when trying to handle razorpay button")
          console.error(error)
          const e = this.getError(error)
          console.error(e)
        }
      }
    },
  },
  mounted() {
    this.load()
  },
  destroyed() {
    try {
      document.getElementById("razorpay-script").remove();
    } catch (error) {
      console.log(error);
    }
  },
}
</script>

<style lang="scss">
#razorpay-button {
  background-color: lighten(#f09000, 20%);
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.busy {
    cursor: initial;
  }
  &:not(.busy):hover {
    background-color: lighten(#f09000, 10%);  
  }
}
.razorpay-checkout-frame {
  padding-top: env(safe-area-inset-top)!important;
}
</style>