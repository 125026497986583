<template>
  <div>
    <v-icon size="24" class="pointer" @click.stop.prevent="openScanner()">camera_alt</v-icon>
    <v-dialog v-model="QrScannerModal" max-width="462" content-class="qr-code-dialog">
      <v-card>
        <v-card-title>
          <h3>Scan QR Code</h3>
        </v-card-title>
        <v-alert :value="error" type="error">{{ error }}</v-alert>
        <v-card-text v-if="QrScannerModal || cameraAccessGranted">
          <template v-if="!error">
            <div class="qrcode-camera__container">
              <QrcodeStream @decode="onDecode" @init="onInit" class="mb-2">
                <div class="loading-indicator" v-if="loading">Loading...</div>
              </QrcodeStream>
            </div>
            * Dim lighting may hinder scanning
          </template>
          <span v-else-if="showCameraAccessInstruction" v-html="cameraAccessInstruction"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" @click="QrScannerModal = false">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn v-if="!!error" color="green" flat="flat" @click="openScanner(true)">Retry</v-btn>
          <!-- <v-btn color="gray" flat="flat" @click="forceCapture()">Force Capture Id</v-btn>
          <v-btn color="gray" flat="flat" @click="forceCaptureToken()">Force Capture Token</v-btn> -->
          <!-- <v-btn color="gray" flat="flat" @click="forceCaptureOfflineRegistrationQR()">Offline Registration QR</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { Capacitor } from "@capacitor/core";

export default {
  data() {
    return {
      QrScannerModal: false,
      loading: false,
      error: false,
      showCameraAccessInstruction: false,
      isRestarted: false,
      cameraAccessGranted: false,
    };
  },
  components: {
    QrcodeStream,
  },
  computed: {
    cameraAccessInstruction() {
      const platform = Capacitor.getPlatform();
      if (platform == "android")
        return `
          Granting Camera Access:
          <ul>
            <li>Open up "Settings"</li>
            <li>Find the "Plugzio" app. This maybe under App Management depending on your phone.</li>
            <li>Allow the Camera permission for the app.</li>
          </ul>
        `;
      if (platform == "ios")
        return `
          Granting Camera Access:
          <ul>
            <li>Open up "Settings"</li>
            <li>Scroll down and find the "Plugzio" app</li>
            <li>Toggle the "Camera" option under the "Allow Plugzio to Access" menu</li>
          </ul>
        `;
      return "";
    },
  },
  methods: {
    openScanner(retry = false) {
      if (retry) this.QrScannerModal = false;
      this.$nextTick(() => {
        this.$emit("captured", "clear");
        this.QrScannerModal = true;
      });
    },
    onDecode(evt) {
      console.log(evt);
      this.$emit("captured", evt);
      this.QrScannerModal = false;
    },
    forceCapture() {
      this.onDecode("https://my.plugz.io/activate-plug?external_id=aryawifi1");
    },
    forceCaptureToken() {
      this.onDecode("bfa26c866cbdeb3129e8d6d0353e8288e47de670863eb468ea64d7e0bb6510b9");
    },
    forceCaptureOfflineRegistrationQR() {
      this.onDecode(`{"deviceId":"aryawifi4","secretToken":"776befb5e72c2e1b98646b22ba7a4e18b2ecb95c30ba758ee1e5a0dcdc8ae6db000"}`);
    },
    async onInit(promise) {
      this.loading = true;
      this.showCameraAccessInstruction = false;
      let isError = false;
      try {
        await promise;
      } catch (error) {
        isError = true;
        if (error.name === "NotAllowedError") {
          this.error = `ERROR: Please allow Plugzio to access your device's camera.`;
          if (Capacitor.getPlatform() == "web") this.error += " Permissions can be configured in your browser's settings.";
          this.showCameraAccessInstruction = true;
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      } finally {
        this.loading = false;
        if (!isError) {
          if (!this.isRestarted) {
            console.log("no error, restart scanner in 1 seconds");
            this.QrScannerModal = false;
            this.isRestarted = true;
            setTimeout(() => {
              this.QrScannerModal = true;
              this.cameraAccessGranted = Capacitor.isNativePlatform(); // camera access true if accessed via mobile app
            }, 1000);
          } 
        }
      }
    },
  },
  watch: {
    QrScannerModal() {
      this.error = false;
    },
  },
};
</script>

<style lang="scss">
.qrcode-camera__container {
  height: 50vh;
}
.v-dialog__content--active:has(.qr-code-dialog) {
  z-index: 1004!important; // above default dialog z-index
}
</style>
