<template>
  <div>
    <v-hover>
      <v-btn
        small
        dark
        color="green"
        class="plugzio-button create-button mx-0 px-3"
        style="min-width: auto"
        round
        outline
        :loading="loading"
        @click="showModal = true"
        slot-scope="{ hover }"
      >
        <v-scale-transition group>
          <v-icon size="18" style="margin: 0" key="icon">phonelink_erase</v-icon>
          <span v-if="hover" class="ml-1" key="description">Unregister Device</span>
        </v-scale-transition>
      </v-btn>
    </v-hover>
    <v-dialog v-model="showModal" max-width="360">
      <v-card>
        <v-card-title>
          <h3>Unregister Device</h3>
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <v-layout xs12 justify-center>
            <v-flex xs12 class="text-xs-center">
              <div class="text-xs-center">
                <h3 class="error--text">Warning</h3>
              </div>
              <div class="text-xs-left mt-4">
                <ul>
                  <li>Users will not be able to activate an unregister device</li>
                  <li>Unregistering a device exposes the device so other managers can register the device to their account</li>
                  <li>Unregistering a device removes all historical session data from the manager's account</li>
                  <li>Unregistering a device cannot be undone</li>
                </ul>
              </div>
              <p
                v-if="deviceview"
                class="text-xs-left mt-4"
              >
                Please confirm you would like to unregister <strong>{{ deviceview.identifier }}</strong>
                from account <strong>{{ deviceview.owner_username }}</strong>
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-btn color="gray" flat="flat" @click="showModal = false">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat="flat" :loading="loading" @click="submit()">OK</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/library/apis/Api"
export default {
  name: "UnregisterDeviceModal",
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      deviceview: (state) => state.Admin.deviceview,
    }),
  },
  methods: {
    submit() {
      this.$store.dispatch("loading", true);
      Api.adminPlugDecomission({ plugIdentifier: this.deviceview.identifier })
        .then(() => {
          this.$store.dispatch("snackbar", {
            message: "Device unregistered",
          });
          this.showModal = false
          this.$store.commit('Admin/setDeviceView', null)
          this.$emit("unregistered")
        })
        .catch(err => this.error = err)
        .finally(() => {
          this.$store.dispatch("loading", false);
        })
    }
  },
  watch: {
    showModal() {
      this.$store.dispatch("loading", false);
    },
  },
};
</script>
