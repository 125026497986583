<template>
  <div class="mobikwik-container">
    <div
      v-if="error"
      class="mb-4"
    >
      <v-alert 
        v-if="!isMobikwikBalanceInsufficient"
        :value="errorText"
        type="error"
      >
        {{ errorText }}
      </v-alert>

      <v-layout
        v-else
        row
        wrap
      >
        <v-flex xs12 class="pa-2 text-xs-center">
          <p class="flex text-xs-justify">
            Your Mobikwik wallet has insufficient funds. Please topup your wallet on Mobikwik and try again.
          </p>
          <p class="flex text-xs-justify">
            <strong>Balance (INR): {{ mobikwik.balance | numeral("0,0.00") }}</strong>
          </p>
          <v-btn 
            class="plugzio-button mb-2 mt-4" 
            block 
            outline 
            round 
            @click="goToMobikwik"
          >
            Go To Mobikwik 
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <div v-else-if="loading.show">
      <v-progress-circular
        class="loading-circle center-margin"
        size="42"
        indeterminate
        color="orange"
      />
      <h3 class="mt-4">{{ loading.text }}</h3>
    </div>
    <div
      v-else-if="!isActive"
      class="mobikwik-button"
      :class="{
        busy: buttonBusy
      }"
      @click="isActive = true"
    >
      <img
        v-if="!buttonBusy"
        src="../../../assets/mobikwik.png" alt="Mobikwik Logo" height="24px"
      >
      <v-progress-circular
        v-else
        class="loading-circle center-margin"
        size="24"
        indeterminate
        color="white"
      />
    </div>
    <div
      v-else-if="mobikwik.menu === null"
    >
      <v-form ref="mobileNumberForm" @submit="next">
        <v-text-field
          id="mobikwik-mobile-number-field"
          outline
          label="Enter Mobile Number"
          v-model="inputs.phone"
          :maxlength="30"
          type="number"
          :min="0"
          required
          :rules="[validators.required, validators.number]"
        />
      </v-form>
    </div>
    <div
      v-else-if="mobikwik.menu === 'input-otp'"
    >
      <h3 class="text-lg-center font-weight-regular mb-4">OTP has been sent to <strong>{{ (inputs.phone || "").replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3') }}</strong></h3>
      <v-form ref="otpForm" @submit="next">
        <v-text-field
          id="mobikwik-otp-field"
          outline
          label="Enter OTP"
          v-model="inputs.otp"
          required
          :rules="[validators.required]"
        />
      </v-form>
      <v-flex d-flex w-full align-center>
        <div>
          <span class="mr-2">Didn't receive OTP?</span>
          <v-btn
            @click="generateOtp"
            class="ma-0 pa-0"
            color="#4caf50"
            flat
            style="text-transform: initial;height: 2rem;"
          >
            Resend
          </v-btn>
        </div>
      </v-flex>
    </div>
    <div
      v-else-if="mobikwik.menu === 'input-email'"
    >
      <v-form ref="emailForm" @submit="next">
        <v-text-field
          id="mobikwik-email-field"
          outline
          label="Email"
          prepend-inner-icon="account_circle"
          v-model="inputs.email"
          type="email"
          :rules="[validators.email]"
          @keyup.enter.stop="next()"
          @keydown.space.prevent
          required
        />
      </v-form>
    </div>
    <div class="mt-4" />
    <div
      v-if="isActive && !loading.show"
      class="actions"
    >
      <v-btn
        v-if="!error || isMobikwikBalanceInsufficient || regenerateOTPErrors.includes(errorType)"
        color="gray"
        flat="flat"
        class="ma-0"
        @click="back"
      >
        {{ backButtonText }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        flat="flat"
        class="ma-0"
        @click="next"
      >
        {{ nextButtonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validators, MobikwikHelpers } from "@/library/helpers"
import Api from '@/library/apis/Api';
import { Capacitor } from "@capacitor/core";

const errors = {
  otpInvalid: "either otp missing or invalid otp",
  noTransactionPermission: "User not allowed to do Transaction. Please file a support ticket.",
  tokenInvalid: "Either Invalid Token (Expiry or Token mismatch) or Token mismatched due to transaction amount exceeding authorized amount",
  insufficientBalance: "Insufficient balance",
}

export default {
  name: "Mobikwik",
  props: {
    wallet: {
      type: Object,
      default: () => ({}),
      required: true
    },
    amount: {
      type: String,
      default: "0",
      required: true
    },
  },
  data() {
    return {
      button: null,
      error: null,
      buttonBusy: false,
      inputs: {
        // phone: "9557096450", // Ashish number
        // phone: "8527802823", // Sachin number
        // phone: "7906369162", // Ashish new number
        phone: null,
        otp: null,
        email: null
      },
      loading: {
        show: false,
        text: null
      },
      mobikwikToken: null,
      mobikwikTopupUrl: null,
      plugzioOrderId: null
    }
  },
  computed: {
    ...mapState({
      mobikwik: state => state.Wallet.topup.mobikwik,
    }),
    isActive: {
      get() {
        return this.mobikwik.active
      },
      set(v) {
        this.$store.commit("Wallet/setTopupMobikwikState", { active: v })
      }
    },
    validators: () => validators,
    isMobikwikBalanceInsufficient() {
      return this.error === "Insufficient balance"
    },
    savedTokens() {
      return MobikwikHelpers.getSavedTokens()
    },
    errorText() {
      const errorTexts = {
        otpInvalid: `Invalid OTP for mobile ${this.inputs.phone}`,
        noTransactionPermission: "This account is not allowed to do transactions. Please contact Mobikwik."
      }
      return errorTexts[this.errorType] || this.error
    },
    errorType() {
      return this.error ? this.getErrorType(this.error) : null
    },
    nextButtonText() {
      if (this.isMobikwikBalanceInsufficient) return "DONE"
      if (this.errorType === "otpInvalid") return "RETRY"
      if (this.error) return "OK"
      return "NEXT"
    },
    backButtonText() {
      if (this.isMobikwikBalanceInsufficient) {
        return "CANCEL"
      }
      return "BACK"
    },
    mobikwikSettings() {
      const mobikwikSettings = window.mobikwikSettings || {}
      const redirectTo = Capacitor.isNativePlatform() ? window.API_URL : window.location.origin;
      return {
        ...mobikwikSettings,
        redirectUrl: `${redirectTo}${window.location.pathname}?verify-mobikwik=true_${this.plugzioOrderId}_${this.inputs.phone}_${this.mobikwikToken}_`
      }
    },
    regenerateOTPErrors() {
      return ["otpInvalid"]
    }
  },
  watch: {
    error(v) {
      if (!!v && this.errorType !== "insufficientBalance") this.mobikwikToken = null // clear token upon error
      this.$emit("error", v);
    },
    "inputs.phone" () {
      this.inputs.phone = this.inputs.phone.replace(/[^0-9]/gi, "");
    },
    mobikwikToken(v) {
      MobikwikHelpers.updateSavedTokens(this.inputs.phone, v)
    }
  },
  methods: {
    getErrorType(error) {
      return Object.keys(errors).find(key => error.toLowerCase() === errors[key].toLowerCase())
    },
    back() {
      if (this.isMobikwikBalanceInsufficient) {
        this.$store.commit("Wallet/resetTopupMobikwikState");
        this.$emit("failed")
        return
      }
      switch (this.mobikwik.menu) {
        case null:
          this.$store.commit("Wallet/setTopupMobikwikState", { active: false });
          break;
        case "input-otp":
          if (this.regenerateOTPErrors.includes(this.errorType)) {
            this.error = null
            break;
          }
          this.$store.commit("Wallet/setTopupMobikwikState", { menu: null });
          break;
        case "input-email":
          this.$store.commit("Wallet/setTopupMobikwikState", { menu: null });
          break;
      }
    },
    async next() {
      if (this.error) {
        if (this.isMobikwikBalanceInsufficient) {
          // await this.proceedTopup(false, true)
          this.$store.commit("Wallet/resetTopupMobikwikState");
          this.$emit("failed")
          location.reload()
          return
        }
        if (!this.errorType || ["noTransactionPermission", "tokenInvalid"].includes(this.errorType)) {
          this.error = null
          this.$store.commit("Wallet/resetTopupMobikwikState");
          this.$emit("failed")
          return
        }
      }

      switch (this.mobikwik.menu) {
        case null:
          if (this.$refs.mobileNumberForm.validate()) {
            this.loading.show = true
            try {
              await this.generateOtp()
            } catch (error) {
              return
            }
            this.loading.show = false
            this.loading.text = null
            this.$store.commit("Wallet/setTopupMobikwikState", { menu: "input-otp" });
          }
          break;
        case "input-otp":
          if (this.regenerateOTPErrors.includes(this.errorType)) {
            this.error = null
            try {
              await this.generateOtp()
            } catch (e) {}
            return
          }
          if (this.$refs.otpForm.validate()) {
            this.loading.show = true
            try {
              this.loading.text = "Checking user exist..."
              try {
                // check user exist, if not exist then throw error
                await Api.mobikwikCheckExistingUser(this.inputs.phone)
              } catch (error) {
                this.loading.show = false
                if (this.getErrorType(error) === "noTransactionPermission") throw error
                this.$store.commit("Wallet/setTopupMobikwikState", { menu: "input-email" });
                return
              }
              await this.generateToken()
              await this.createOrder()
              await this.proceedTopup()
            } catch (error) {
              this.loading.show = false
              this.loading.text = null
              this.error = error
            }
          }
          break;
        case "input-email":
          if (this.$refs.emailForm.validate()) {
            this.loading.show = true
            try {
              this.loading.text = "Creating user..."
              const { token } = await Api.mobikwikCreateUser(this.inputs.phone, this.inputs.email, this.inputs.otp)
              if (!token) throw "Failed to generate token"
              
              this.mobikwikToken = token

              await this.createOrder()
              await this.proceedTopup()
            } catch (error) {
              this.loading.show = false
              this.loading.text = null
              this.error = error
            }
          }
          break;
      }
    },
    async generateToken(params = {}) {
      const { regenerate, showError, showLoading } = { regenerate: false, showError: true, showLoading: true, ...params }
      return new Promise(async (resolve, reject) => {
        try {
          if (showLoading) {
            this.loading.show = true
            this.loading.text = `${!regenerate ? "Generating" : "Regenerating"} token...`
          }
          const { token } = !regenerate ? await Api.mobikwikGenerateToken(this.inputs.phone, this.amount, this.inputs.otp) : await Api.mobikwikTokenRegenerate(this.inputs.phone, this.mobikwikToken)
          if (!token) throw "Failed to generate token"

          this.mobikwikToken = token
          resolve(token)
        } catch (error) {
          if (showLoading) {
            this.loading.show = false
            this.loading.text = null
          }
          if (!showError) return reject(error)
          this.error = error
          reject(error)
        }
      })
    },
    async createOrder() {
      return new Promise(async (resolve) => {
        try {
          this.error = null
          this.loading.show = true

          this.loading.text = "Creating Order..."
          const { orderId } = await Api.walletMobikwikCreateOrder({ walletId: this.wallet.id })
          this.plugzioOrderId = orderId
          resolve(true)
        } catch (error) {
          this.loading.show = false
          this.loading.text = null
          this.error = error || "Failed to process topup"
          resolve(false)
        }
      })
    },
    async proceedTopup(create_mobikwik_topup_url = true, check_status = false) {
      return new Promise(async (resolve) => {
        try {
          this.error = null
          this.loading.show = true

          let statusResponse = false
          if (check_status) {
            this.loading.text = "Checking transaction status..."
            statusResponse = await Api.mobikwikCheckStatus(this.plugzioOrderId)
          }
          if (!statusResponse || statusResponse.statusmessage !== "success") {
            this.loading.text = "Checking wallet balance..."
            const { balanceamount, status: checkBalanceStatus } = await Api.mobikwikCheckBalance(this.inputs.phone, this.mobikwikToken)
            this.$store.commit("Wallet/setTopupMobikwikState", { balance: balanceamount });
            if (checkBalanceStatus !== "SUCCESS") throw "Failed to check balance"
            if (parseFloat(balanceamount) < parseFloat(this.amount)) {
              if (create_mobikwik_topup_url) {
                const checksum = await Api.mobikwikMakeChecksum({
                  arg: `'${this.amount}''${this.inputs.phone}''${this.mobikwikSettings.merchantName}''${this.mobikwikSettings.merchantId}''${this.plugzioOrderId}''${this.mobikwikSettings.redirectUrl}''${this.mobikwikToken}'`
                })
                this.mobikwikTopupUrl = `${this.mobikwikSettings.endpoint}/walletapis/addmoneytowalletanddebit?checksum=${checksum}&amount=${this.amount}&mid=${this.mobikwikSettings.merchantId}&merchantname=${this.mobikwikSettings.merchantName}&redirecturl=${encodeURIComponent(this.mobikwikSettings.redirectUrl)}&orderid=${this.plugzioOrderId}&token=${this.mobikwikToken}&cell=${this.inputs.phone}`
              }

              throw "Insufficient balance"
            }

            this.loading.text = "Debiting wallet..."
            const debitResponse = await Api.mobikwikProcessDebit(this.inputs.phone, this.mobikwikToken, this.plugzioOrderId, "debit", this.amount, "Debit")
            if (!debitResponse || debitResponse.status !== "SUCCESS") throw "Failed to debit wallet"

            this.loading.text = "Checking transaction status..."
            const secondStatusResponse = await Api.mobikwikCheckStatus(this.plugzioOrderId)
            if (!secondStatusResponse || secondStatusResponse.statusmessage !== "success") throw "Transaction failed"
          }
          this.generateToken({ regenerate: true, showError: false, showLoading: false })
            .catch(() => this.mobikwikToken = null)

          this.loading.text = "Topup plugzio..."
          const success = await Api.walletTopupMobikwik(JSON.stringify({ orderId: this.plugzioOrderId }))
          if (!success) throw "Failed to topup plugzio"

          this.$store.commit("Wallet/resetTopupMobikwikState");
          this.$emit("success")
          resolve(true)
        } catch (error) {
          this.loading.show = false
          this.loading.text = null
          this.error = error || "Failed to process topup"
          resolve(false)
        }
      })
    },
    goToMobikwik() {
      if (this.mobikwikSettings.openInTab) window.open(this.mobikwikTopupUrl || "https://www.mobikwik.com/", "_blank")
      else window.location.replace(this.mobikwikTopupUrl || "https://www.mobikwik.com/")
    },
    async generateOtp() {
      return new Promise(async (resolve, reject) => {
        try {
          const foundToken = this.savedTokens[this.inputs.phone] || null
          this.mobikwikToken = foundToken && foundToken.expiry > this.$moment().unix() ? foundToken.token : null
          if (!!this.mobikwikToken) {
            this.loading.show = false
            this.loading.text = null
            this.$store.commit("Wallet/setTopupMobikwikState", { menu: "input-otp" });
            try {
              await this.createOrder()
              await this.proceedTopup()
            } catch (error) {
              this.loading.show = false
              this.loading.text = null
              this.error = error
            }
            resolve(true)
            return
          }
          this.loading.text = "Requesting OTP..."
          this.loading.show = true
          await Api.mobikwikGenerateOTP(this.inputs.phone, this.amount)
          this.loading.show = false
          resolve(true)
        } catch (error) {
          this.error = "Failed to send OTP"
          this.loading.show = false
          this.loading.text = null
          reject(error)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.mobikwik-button {
  background-color: lighten(#f09000, 20%);
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.busy {
    cursor: initial;
  }
  &:not(.busy):hover {
    background-color: lighten(#f09000, 10%);  
  }
}
.mobikwik-container {
  .actions {
    display: flex;
    flex-direction: row;
  }
}
</style>