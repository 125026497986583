<template>
  <div id="paypal-button" />
</template>

<script>
import Api from "@/library/apis/Api";
export default {
  name: "Paypal",
  props: {
    wallet: {
      type: Object,
      default: () => ({}),
      required: true
    },
    amount: {
      type: String,
      default: "0",
      required: true
    },
  },
  data() {
    return {
      button: null,
      error: null,
      busy: false
    }
  },
  computed: {
    paypalClientId() {
      return PAYPAL_CLIENT_ID;
    },
  },
  watch: {
    error(v) {
      this.$emit("error", v);
    },
    busy(v) {
      this.$emit("busy", v);
    },
  },
  methods: {
    loadScript(callback) {
      const existingScript = document.getElementById("paypalScript");
      if (existingScript) existingScript.parentNode.removeChild(existingScript);

      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?currency=${this.wallet.currency}&client-id=${this.paypalClientId}`;
      script.id = "paypalScript";
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };

      if (existingScript && callback) callback();
    },
    load() {
      let self = this;
      try {
        this.button = null;
      } catch (e) {
        console.log(e);
      }
      this.loadScript(() => {
        this.$nextTick(() => {
          document.getElementById("paypal-button").innerHTML = "";
          self.button = paypal
            .Buttons({
              createOrder: (data, actions) => {
                self.error = null;
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: self.amount.toString(),
                        currency_code: self.wallet.currency.toString(),
                      },
                      description: self.wallet.id.toString(),
                    },
                  ],
                });
              },
              onApprove: (data, actions) => {
                self.busy = true;
                if (this.$gtm) {
                  this.$gtm.trackEvent({
                    event: "Server Call",
                    "server-call-action": "topup-success"
                  });
                }
                return actions.order.capture().then(async (details) => {
                  await this.onApproveAction(data);
                });
              },
              onError: (error) => {
                self.busy = false;
                if (!self.amount || self.amount === 0) {
                  self.error = "Please enter an amount greater than $0.00";
                } else {
                  self.error = "PayPal: Could not complete request. Please try again.";
                }
                console.log("paypal error:" + error);

                if (this.$gtm) {
                  this.$gtm.trackEvent({
                    event: "Server Call",
                    "server-call-action": "topup-failed"
                  });
                }
              },
              onCancel: (cancel) => {
                self.busy = false;
              },
              onClick: (data, actions) => {
                if (this.$gtm) {
                  this.$gtm.trackEvent({
                    event: "I-frame Click",
                    iframe_element: `paypal-${data.fundingSource}-button`,
                  });
                }
              },
            })
            .render("#paypal-button");
        });
      });
    },
    async onApproveAction(data) {
      this.busy = true;
      try {
        await Api.walletTopupPaypal(JSON.stringify({ orderId: data.orderID }))
        this.$emit("done")
      } catch (error) {
        console.log(error)
      }
      this.busy = false;
    },
  },
  mounted() {
    this.load()
  },
  destroyed() {
    try {
      document.getElementById("paypalScript").remove();
    } catch (error) {
      console.log(error);
    }
  },
}
</script>