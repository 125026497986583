<template>
  <v-dialog v-model="show" max-width="350">
    <v-card>
      <v-card-title>
        <h3>Reboot Device</h3>
      </v-card-title>
      <v-card-text>
        <v-flex class="flex flex-column justify-center align-center text-xs-center" v-if="busy">
          <v-progress-circular class="loading-circle mt-4" size="40" indeterminate color="black" />
          <div class="my-4">
            <h2>Rebooting device...</h2>
          </div>
        </v-flex>
        <template v-else>
          <div v-if="error" class="justify-center align-center text-xs-center">
            <v-icon class="text-xs-center" size="50" color="red">cancel</v-icon>
            <p class="text-xs-center my-3">{{ `Reboot failed. Device ${this.device.identifier} is offline` }}</p>
          </div>
          <div v-else class="justify-center align-center text-xs-center">
            <v-icon class="text-xs-center" size="50" color="green">check_circle</v-icon>
            <p class="text-xs-center my-3">Reboot request sent to {{ device.identifier }}</p>
          </div>
        </template>
      </v-card-text>
      <v-card-actions>
        <template>
          <v-spacer></v-spacer>
          <v-btn v-if="busy" color="gray" flat="flat" @click="show = false">Cancel</v-btn>
          <v-btn v-else color="green darken-1" flat="flat" @click="$emit('close')">
            OK
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    busy: Boolean,
    error: Boolean,
    device: Object
  },
};
</script>
