<template>
  <v-dialog v-model="showDialog" max-width="350">
    <v-card>
      <v-card-title>
        <h3 id="device-activation-dialog">Device Activation</h3>
        <v-spacer></v-spacer>
        <v-tooltip bottom :offset-y="-10">
          <template v-slot:activator="{ on }">
            <v-icon class="tooltip-icon mb-0 pull-right" v-on="on">
              info
            </v-icon>
          </template>
          <span class="tooltip-content">
            This is a preview of what users will see when attempting to activate your device. 
            Note that the payment plan information is for display purposes only.
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="text-xs-left">
        <v-flex xs12>
          <div><h4 class="border-bottom">Management Information</h4></div>
          <div class="mt-2">
            <p class="mb-1" style="display:flex;">
              <span style="white-space:nowrap">Manager:</span> <strong id="device-activation-manager-name" class="ml-1" style="overflow-wrap:anywhere">{{ name }}</strong>
            </p>
            <p class="mb-1" style="display:flex;" v-if="!!email">
              <span style="white-space:nowrap">Email:</span> <strong id="device-activation-email" class="ml-1" style="overflow-wrap:anywhere">{{ email }}</strong>
            </p>
            <p v-if="!!phone" style="display:flex;">
              <span style="white-space:nowrap">Phone #:</span> <strong id="device-activation-phone-number" class="ml-1" style="overflow-wrap:anywhere">{{ phone }}</strong>
            </p>
          </div>
        </v-flex>
        <v-flex xs12 class="mt-4">
          <p>Additional information:</p>
          <p id="device-activation-message" style="overflow-y:auto;max-height:300px;overflow-wrap:anywhere;">{{ message || "-" }}</p>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn id="device-activation-back-button" color="gray" flat="flat" @click="showDialog = false">BACK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    name: {
      default: null,
      required: true,
    },
    email: {
      default: null,
    },
    phone: {
      default: null,
    },
    message: {
      default: null,
    },
    preview: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showDialog: this.value,
    };
  },
  watch: {
    showDialog: function(value) {
      this.$emit("input", value);
    },
    value: function(value) {
      this.showDialog = value;
    },
  },
};
</script>
