<template>
  <div class="d-inline-block text-xs-right">
    <div class="d-inline-block" :class="{ hide: responsive }">
      <v-btn id="calendar-timezone" small flat @click="show = true" class="ml-4 mr-0">
        <span class="text-transform-none">Timezone: </span>
        <strong class="ml-1"> {{ timezoneText }}</strong>
      </v-btn>
    </div>
    <v-dialog v-model="show" max-width="290" lazy>
      <v-card>
        <v-card-title id="calendar-dialog-title"><h3>Timezone</h3></v-card-title>
        <v-card-text class="py-0">
          <TimezoneInput v-model="timezone" />
        </v-card-text>
        <v-card-actions class="mt-4" style="border-top:1px solid #eaeaea">
          <template>
            <v-spacer></v-spacer>
            <v-btn id="calendar-dialog-ok" color="green darken-1" flat="flat" @click="show = false">
              OK
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimezoneInput from '@/components/inputs/TimezoneInput.vue';

export default {
  name: "TimezoneInputModal",
  components: {
    TimezoneInput
  },
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters("Global", {
      timezoneText: "getTimezoneText",
    }),
    timezone: {
      get() {
        return this.$store.state.Global.timezone;
      },
      set(v) {
        this.$store.dispatch("Global/setTimezone", v);
      },
    },
    responsive() {
      return this.$store.state.responsive;
    },
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>
