<template>
  <v-layout justify-center align-center>
    <v-flex v-show="menu === 'paypal-profile-name'" xs12>
      <v-text-field outline label="Profile Name" v-model="paypalInputs.profileName" :rules="[validators.required]" required :maxlength="20" />
    </v-flex>
    <v-flex xs12 v-show="menu === 'paypal-email'">
      <v-text-field outline label="Paypal E-mail Address" v-model="paypalInputs.paypalEmail" :rules="[validators.required, validators.email]" required />
    </v-flex>
  </v-layout>
</template>

<script>
import validators from "@/library/helpers/validators";

export default {
  name: "PaypalWithdrawalForm",
  props: {
    menu: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
    inputs: {
      type: Object,
      default: () => ({
        profileName: null,
        paypalEmail: null,
      }),
    },
  },
  computed: {
    validators: () => validators,
    paypalInputs: {
      get() {
        return this.inputs;
      },
      set(v) {
        return this.$emit("inputs:paypal", v);
      },
    },
  },
  watch: {
    "paypalInputs.paypalEmail": function(newValue, oldValue) {
      this.$nextTick(() => {
        if (newValue && oldValue !== newValue) {
          this.paypalInputs.paypalEmail = newValue.toLowerCase().trim();
        }
      });
    },
  },
  methods: {
    resetPaypalInputs() {
      this.inputs.profileName = null;
      this.inputs.paypalEmail = null;
      this.$emit("inputs:paypal", this.inputs);
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (!this.isEdit) {
        this.resetPaypalInputs();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.add-profile-button {
  border-radius: 10px;
}
.profile-name-field {
  margin: 16px;
}
</style>
