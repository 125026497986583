<template>
  <div>
    <v-hover>
      <v-btn
        small
        dark
        color="green"
        class="plugzio-button create-button mx-0 px-3"
        style="min-width: auto"
        round
        outline
        :loading="loading"
        @click="showModal = true"
        slot-scope="{ hover }"
      >
        <v-scale-transition group>
          <v-icon size="18" style="margin: 0" key="icon">sync_alt</v-icon>
          <span v-if="hover" class="ml-1" key="description">Transfer Config & Data</span>
        </v-scale-transition>
      </v-btn>
    </v-hover>
    <v-dialog v-model="showModal" max-width="360">
      <v-card>
        <v-card-title>
          <h3>Transfer Config & Data</h3>
          
        </v-card-title>
        <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
        <v-card-text>
          <v-layout xs12 justify-center>
            <v-flex v-if="step === 'input'" xs12 class="text-xs-center">
              <v-form
                ref="transferDeviceForm"
                @submit="nextButton.action" 
              >
                <div class="text-xs-center">
                  <h3 class="error--text">Warning</h3>
                </div>
                <div class="text-xs-left mt-3 mb-2">
                  <ul>
                    <li class="mb-2">Please make sure the device you are transferring data to is not registered</li>
                    <li class="mb-2">Transferring data will transfer all session, subscription and access configuration to a new device</li>
                    <li class="mb-2">Transferring data will unregister the original device from the management account.</li>
                    <li>Once the transfer is completed, please make sure set the mode of the new device.</li>
                  </ul>
                </div>
              
                <hr class="mb-3" style="height:4px;background-color:black">

                <h4 class="text-xs-left mb-2" style="font-weight: normal">
                  Transfer configuration and data from device <strong>{{ deviceview.identifier }}</strong> to:
                </h4>
                <v-text-field
                  id="transfer-device-to-field"
                  outline
                  ref="tranferDeviceToField"
                  label="Device ID"
                  v-model="transferTo"
                  :error="!!error"
                  :rules="[validators.required]"
                  @keyup.enter="nextButton.action"
                  required
                  :disabled="loading"
                />
              </v-form>
            </v-flex>
            <v-flex v-else-if="step === 'confirmation'" xs12 class="text-xs-center">
              <div class="text-xs-center">
                <h3 class="error--text">Warning</h3>
              </div>
              <div class="text-xs-left mt-4">
                <ul>
                  <li>This action transfer the configuration from one device to another. This includes management ownership, payment plan configuration, subscription time and access control</li>
                  <li>Device transfers cannot be undone</li>
                </ul>
              </div>
              <p
                v-if="deviceview"
                class="text-xs-left mt-4"
              >
                Please confirm you would like to transfer the configurations and data from device <strong>{{ deviceview.identifier }}</strong>
                to device <strong>{{ transferTo }}</strong>
              </p>
            </v-flex>
            <v-flex
              v-else-if="step === 'done'"
              xs12 
              class="text-xs-center"
            >
              <div class="text-xs-center">
                <h3 class="success--text">Success</h3>
              </div>
              <div class="text-xs-center mt-1">
                <h4>Transfer Completed!</h4>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions
          class="mt-4"
        >
          <v-btn
            v-if="backButton.show"
            color="gray"
            flat="flat"
            :loading="loading"
            @click="backButton.action"
          >
            {{ backButton.text }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            flat="flat"
            :loading="loading"
            @click="nextButton.action"
          >
            {{ nextButton.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/library/apis/Api"
import validators from "@/library/helpers/validators"
export default {
  name: "TransferDeviceModal",
  data() {
    return {
      showModal: false,
      error: false,
      step: "input",
      transferTo: null
    };
  },
  computed: {
    validators: () => validators,
    ...mapState({
      loading: (state) => state.loading,
      deviceview: (state) => state.Admin.deviceview,
    }),
    backButton() {
      let show = true;
      let action = () => this.showModal = false; 
      this.error = null
      let text = "Cancel"

      switch (this.step) {
        case "confirmation":
          show = true
          action = () => this.step = "input"
          text = "Back"
          break;
        case "done":
          show = false
          action = () => {}
          text = null
          break;
      
        default:
          show = true
          action = () => this.showModal = false
          text = "Cancel"
          break;
      }

      return { show, action, text }
    },
    nextButton() {
      let action = () => this.step = "confirmation"
      let text = "Next"

      switch (this.step) {
        case "confirmation":
          action = () => this.submit()
          text = "Confirm"
          break;
        case "done":
          action = () => {
            this.$router.push({ path: "/admin/deviceview", query: { device: this.transferTo } })
          }
          text = "Refresh Page"
          break;
      
        default:
          action = () => {
            if (this.$refs.transferDeviceForm.validate()) {
              this.step = "confirmation"
            }
          }
          text = "Next"
          break;
      }

      return { action, text }
    }
  },
  methods: {
    submit() {
      this.$store.dispatch("loading", true);
      Api.adminPlugReplace({ oldIdentifier: this.deviceview.identifier, newIdentifier: this.transferTo })
        .then(() => {
          this.step = "done"
        })
        .catch(err => this.error = err)
        .finally(() =>this.$store.dispatch("loading", false))
    }
  },
  watch: {
    showModal() {
      this.$store.dispatch("loading", false);
    },
  },
};
</script>
