<template>
  <div>
    <v-dialog v-if="!!selectedStat.name" max-width="1080" v-model="detailsDialog">
      <v-card class="session-tile session-history owner-session">
        <v-card-title class="py-1 pr-2">
          <h3 :id="`${titleForId}-log-title`">{{ title }}</h3>
          <v-spacer></v-spacer>
          <slot></slot>
          <div v-if="allowChangeShowBy" class="mr-4">
            <strong class="mr-4">Show By:</strong>
            <v-btn color="success" :id="`show-by-date-button`" @click="showBy = 'time'" :outline="showBy != 'time'" small>
              Date
            </v-btn>
            <v-btn color="success" :id="`show-by-user-button`" @click="showBy = 'user'" :outline="showBy != 'user'" small>
              User
            </v-btn>
            <v-btn color="success" :id="`show-by-device-button`" @click="showBy = 'device'" :outline="showBy != 'device'" small>
              Device
            </v-btn>
          </div>

          <v-btn :id="`${titleForId}-log-close-button`" icon @click="detailsDialog = false" class="mr-0">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="pt-2">
            <StatisticsBarGraph
              :raw-data="data"
              :field="selectedStat.field"
              :loading="statsLoading"
              :xAxis="xAxisField"
              @clicked="showFilterConfirmationDialog"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filterDataConfirmation.show" max-width="400">
      <v-card>
        <v-card-title>
          <h3>Filter Confirmation</h3>
        </v-card-title>
        <v-card-text class="text-xs-justify">
          <v-flex class="flex flex-column items-center">
            <p v-html="filterDataConfirmation.text" />
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" @click="filterDataConfirmation.show = false">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" flat="flat" @click="goFilter">
            CONFIRM
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StatisticsBarGraph from "./../graphs/StatisticsBarGraph";
import { mapGetters, mapState } from "vuex";
import SessionHelpers from "./../../library/helpers/SessionHelpers";
import { cloneDeep } from "lodash-es";
import sort from "./../../mixins/sort.js";

export default {
  props: {
    selected: {
      required: true,
    },
  },
  components: {
    StatisticsBarGraph,
  },
  mixins: [sort],
  data() {
    return {
      selectedStat: {},
      statsLoading: false,
      detailsDialog: false,
      showBy: "time",
      filterDataConfirmation: {
        show: false,
        text: "",
        data: null,
      },
    };
  },
  computed: {
    ...mapState({
      sessionFilters: (state) => state.Session.filters,
      time: (state) => state.timeSpan,
      role: (state) => state.Auth.role,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters({
      storedTime: "storedTime",
    }),
    title() {
      if (this.selectedStat.wallet) return this.selectedStat.name + " Log (" + this.selectedStat.wallet.currency + ")";
      if (this.selectedStat.name) return this.selectedStat.name;
      return "";
    },
    sessions() {
      const sessions = cloneDeep(this.$store.state.sessions);
      return SessionHelpers.filter(sessions, this.sessionFilters);
    },
    data() {
      let sourceData = [];
      if (this.showBy == "time") sourceData = cloneDeep(this.time);
      if (this.showBy == "user") sourceData = cloneDeep(this.sessionUsers);
      if (this.showBy == "device") sourceData = cloneDeep(this.sessionDevices);
      let data = [];
      for (let i = 0; i < sourceData.length; i++) {
        let v = cloneDeep(sourceData[i]);
        data.push(this.computateSession(v));
      }
      return data;
    },
    titleForId() {
      if (this.selected.field == "durationHours") return "duration";
      if (this.selected.field == "consumptionkWh") return "consumption";
      return this.selected.field;
    },
    sessionUsers() {
      const users = [];
      for (let i = 0; i < this.sessions.length; i++) {
        const session = this.sessions[i];
        if (users.findIndex((u) => u.id == session.user_id) >= 0) continue;
        users.push({
          id: session.user_id,
          username: session.username,
        });
      }
      return users.sort(this.compareValues("username"));
    },
    sessionDevices() {
      const devices = [];
      for (let i = 0; i < this.sessions.length; i++) {
        const session = this.sessions[i];
        if (devices.findIndex((d) => d.id == session.plug_id) >= 0) continue;
        devices.push({
          id: session.plug_id,
          identifier: session.plug_identifier,
          description: session.plug_description,
        });
      }
      return devices.sort(this.compareValues("identifier"));
    },
    allowChangeShowBy() {
      return this.role == "owner" || (this.role == "admin" && this.activeAdminView == "managerview");
    },
    xAxisField() {
      if (this.showBy == "time") return "timeSet";
      if (this.showBy == "user") return "username";
      if (this.showBy == "device") return "identifier";
      return "timeSet";
    },
  },
  watch: {
    selected() {
      if (this.selected) this.openDetails(this.selected);
    },
    "filterDataConfirmation.show"(v) {
      if (v) this.detailsDialog = false;
    },
  },
  methods: {
    openDetails(stat) {
      this.selectedStat = stat;
      this.detailsDialog = true;
    },
    computateSession(v) {
      let sessions = cloneDeep(this.sessions);
      const computed = {
        consumption: 0,
        consumptionkWh: 0,
        session: 0,
        revenue: 0,
        expense: 0,
        duration: 0,
        durationHours: 0,
        sessionsWithCharge: 0,
      };
      for (let i = 0; i < sessions.length; i++) {
        const s = sessions[i];
        if (this.showBy == "time" && (s.starttime < v.sinceSet || s.starttime > v.tillSet)) continue;
        if (this.showBy == "user" && s.user_id != v.id) continue;
        if (this.showBy == "device" && s.plug_id != v.id) continue;
        computed.consumption += s.total_consumption;
        computed.consumptionkWh += s.total_consumption / 1000;
        computed.session += 1;
        if (["revenue", "expense"].includes(this.selectedStat.field)) {
          if (this.selectedStat.wallet && s.total_cost > 0) {
            const wallet_id = this.selectedStat.field == "revenue" ? s.owner_wallet_id : s.user_wallet_id;
            if (this.selectedStat.wallet.grouped_ids.includes(wallet_id)) {
              if (this.selectedStat.field == "revenue") computed.revenue += s.total_cost;
              if (this.selectedStat.field == "expense") computed.expense += s.total_cost;
            }
          }
        }
        if (s.endtime) {
          computed.duration += s.endtime - s.starttime;
          computed.durationHours = computed.duration / 3600;
        } else {
          computed.duration += s.duration_raw;
          computed.durationHours = s.duration_raw / 3600;
        }
      }
      computed.revenue = computed.revenue.toFixed(2);
      computed.expense = computed.expense.toFixed(2);

      return { ...v, ...computed };
    },
    showFilterConfirmationDialog(item) {
      if (!this.allowChangeShowBy) return;
      this.filterDataConfirmation.data = this.showBy == "time" ? this.time[item.index] : this.showBy == "user" ? this.sessionUsers[item.index] : this.sessionDevices[item.index];
      if (!this.filterDataConfirmation.data) return;
      if (this.showBy == "time") {
        const formattedDate = {
          since: this.$moment.unix(this.filterDataConfirmation.data.sinceSet).format("YYYY-MM-DD"),
          till: this.$moment.unix(this.filterDataConfirmation.data.tillSet).format("YYYY-MM-DD"),
        };
        if (formattedDate.since == formattedDate.till) return;
        this.filterDataConfirmation.text = `Would you like to filter data by the following date:
        <br/>Date from: <strong>${formattedDate.since}</strong>
        <br/>Date to: <strong>${formattedDate.till}</strong>`;
      }
      if (this.showBy == "user") {
        if (this.sessionUsers.length < 2) return;
        this.filterDataConfirmation.text = `Would you like to filter data by the following user:
          <br/>User: <strong>${this.filterDataConfirmation.data.username}</strong>`;
      }
      if (this.showBy == "device") {
        if (this.sessionDevices.length < 2) return;
        this.filterDataConfirmation.text = `Would you like to filter data by the following device:
          <br/>Device: <strong>${this.filterDataConfirmation.data.identifier}</strong>`;
      }
      this.filterDataConfirmation.show = true;
    },
    goFilter() {
      switch (this.showBy) {
        case "time":
          this.$store.dispatch("setTime", {
            since: this.filterDataConfirmation.data.sinceSet,
            till: this.filterDataConfirmation.data.tillSet,
          });
          break;
        case "user":
          this.$store.commit("Filter/set", { enableRegexSearch: false, search: this.filterDataConfirmation.data.username });
          break;
        case "device":
          this.$store.commit("Filter/set", { enableRegexSearch: false, search: this.filterDataConfirmation.data.identifier });
          break;
      }
      this.filterDataConfirmation.show = false;
    },
  },
};
</script>
